import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { BlockMediaAssetsGalleryQuery } from "../../fragments/NodeMediaAssetFields";
import { colors } from "../../vars/palette";
import MediaAssetBlock from "./MediaAssetBlock";
import { BREAKPOINT_MEDIUM_MAX, Type } from "./Typography";
import { FaBookmark } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";

const StyledIconCopy = styled(Container)<{
  resource?: boolean;
  iconcolor: string;
}>`
  //box-shadow: ${({ resource }) => (resource ? "" : "0px 0px 10px #00000029")};
  padding: ${({ resource }) => (resource ? "32px 0" : "32px 20px")};
  .icon-container {
    display: flex;
    justify-content: flex-end;
    padding: 11px 16px 0 0;
    // font-size: 1.5rem;

    svg {
      ${({ iconcolor }) => (iconcolor ? `color: ${iconcolor};` : "")}
      min-width: 2rem;
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    padding: 30px 0px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .icon-container {
      padding-top: 3px;
    }

    .icon-copy-button-container {
      margin-top: 20px;
    }
  }
`;

const MediaAssetGalleryBlock = ({
  galleryData
}: {
  galleryData: BlockMediaAssetsGalleryQuery;
}) => {
  const [currentLink, setCurrentLink] = useState(null);

  return (
    <>
      <StyledIconCopy iconcolor={colors.green.hex}>
        <Row className="g-0">
          <Col xs={1} sm={1}>
            <div className="icon-container">
              <FaBookmark />
            </div>
          </Col>
          <Col sm={11}>
            <Type.IconCopyHeadline>{galleryData.title}</Type.IconCopyHeadline>
            <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row>
                {Array.isArray(galleryData.mediaAssets) &&
                  galleryData.mediaAssets.map((mediaAsset, i) => {
                    return (
                      <Col xl={4} lg={6} key={`${mediaAsset.title} ${i}`}>
                        <MediaAssetBlock
                          title={mediaAsset.title}
                          webImage={mediaAsset.webVersion}
                          printImage={mediaAsset.printVersion}
                          videoTitle={mediaAsset.videoTitle}
                          videoDescription={mediaAsset.videoDescription}
                          videoUrl={mediaAsset.videoUrl}
                          currentLink={currentLink}
                          setCurrentLink={setCurrentLink}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </Container>
          </Col>
        </Row>
      </StyledIconCopy>
    </>
  );
};

export default MediaAssetGalleryBlock;
