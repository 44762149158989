import { graphql } from "gatsby";
import * as React from "react";
import MediaAssetGalleryBlock from "../components/ui/MediaAssetGalleryBlock";
import { BlockMediaAssetsGalleryQuery } from "../fragments/NodeMediaAssetFields";

const MediaAssetGalleryTest = ({
  data
}: {
  data: { data: BlockMediaAssetsGalleryQuery };
}) => {
  // const data = useStaticQuery(query)
  // console.log("data", data);

  return (
    <>
      <MediaAssetGalleryBlock galleryData={data.data} />
    </>
  );
};

export default MediaAssetGalleryTest;

export const query = graphql`
  {
    data: contentfulBlockMediaAssetGallery(title: { eq: "Schema" }) {
      ...NodeBlockMediaAssetsGalleryFields
    }
  }
`;
